import React, {useEffect} from "react";
import './App.css';
import {
    selectLogin,

    closeLogin,
} from './store/modalsSlice';
import {useSelector, useDispatch} from 'react-redux';
import {Login} from "./features/Login/Login"
import {CustomAlert} from "./features/CustomView/CustomAlert";
import {selectAlertInfo, setAlertInfo} from "./store/CustomViewSlice";
import {autoLoginRequest} from "./features/Login/loginApi";
import {message} from "antd";
import {API_URL} from "./util/constants";

function App() {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const loginVisible = useSelector(selectLogin);
    const alertInfo = useSelector(selectAlertInfo)
    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        const showAlert = (e) => {
            // 处理窗口大小变化的逻辑
            dispatch(setAlertInfo({
                showStatus: true,
                title: "",
                title1: e.detail.content,
                content: ""
            }))
        };
        window.addEventListener('showCustomAlert', showAlert);

        return () => {
            // 组件卸载前移除事件监听
            window.removeEventListener('showCustomAlert', showAlert);
        };
    }, []);

    return (
        <div className="App">
            {contextHolder}
            <div className="pageContainer">
                {loginVisible ? <Login/> : null}
                <CustomAlert
                    info={alertInfo}
                />
            </div>

        </div>

    );
}

export default App;
