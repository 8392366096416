// import { faL } from '@fortawesome/free-solid-svg-icons';
import {faL} from '@fortawesome/free-solid-svg-icons';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
// import { fetchCount } from './counterAPI';

const initialState = {
    customAlert: false,
    login:true,
    // login:sessionStorage.getItem('username')==null?true:false,
    // login: false,
};

export const modalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        openLogin: (state) => {
            state.login = true
        },
        closeLogin: (state) => {
            state.login = false
        }

    },
});

export const selectLogin = (state) => state.modals.login;

export const {
    openLogin,
    closeLogin
} = modalsSlice.actions;

export default modalsSlice.reducer;
