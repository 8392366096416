import { configureStore } from '@reduxjs/toolkit';
import modalsReducer from './modalsSlice'

import CustomViewReducer from './CustomViewSlice';

import loginReducer from "../features/Login/LoginSlice";
export const store = configureStore({
  reducer: {
    modals:modalsReducer,
    login:loginReducer,
    // coursePaymentQuery:coursePaymentQueryReducer,

    CustomView:CustomViewReducer

  },
});
