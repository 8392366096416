import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import MD5 from 'js-md5';
import {
    closeLogin,
} from '../../store/modalsSlice'
import {
    selectUserName,
} from './LoginSlice'
import {loginRequest} from './loginApi';
import styles from './Login.module.css';
import loginBgImg from "./loginBgImg.jpg"
import loginIcon from "./loginIcon.svg";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUserCircle, faLock, faArrowCircleRight, faWarning} from '@fortawesome/free-solid-svg-icons';
import Footer from '../Footer/Footer';
import {API_URL, APP_ID, APP_KEY_LOCAL} from "../../util/constants";
import {Base64} from 'js-base64';
import {message} from 'antd'
export function Login() {
//   const count = useSelector(selectCount);
    const dispatch = useDispatch();
    const [loginUserName, setLoginUserName] = useState('');
    const [password, setPassword] = useState('');
    const [tipMsg, setTipMsg] = useState('');
    const [messageApi, contextHolder] = message.useMessage();
    const userName1 = useSelector(selectUserName)

    // const incrementValue = Number(incrementAmount) || 0;

    useEffect(() => {
    })

    function loginMethod() {
        if (loginUserName == "") {
            setTipMsg('请填写用户名称')
        } else if (password == "") {
            setTipMsg('请填写登录密码')
        } else {
            let token = '';
            const timestamp = new Date().getTime();
            // 获取当前的10位时间戳（秒）
            // var timestamp = Math.floor(Date.now() / 1000);
            let tokenStr = timestamp + "," + loginUserName + "," + APP_ID + "," + MD5(timestamp + loginUserName + APP_KEY_LOCAL)
            token = Base64.encode(tokenStr).toString();


            loginRequest(loginUserName, password, token, (data) => {

                if (data.ret == 1) {
                    let dictionary = data.data;

                    for (let key in dictionary) {
                        if (dictionary.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                            // if(key!="username"&&key!="utoken"&&key!="pwd"&&key!="username_login"){
                            if (key != "pwd") {
                                sessionStorage.setItem(key, dictionary[key])
                            }
                        }
                    }

                    // dispatch(closeLogin())
                    if (data.data.user_type == 3) { //财务主管
                        window.location.replace(API_URL+'/caiwuWeb/index.html')
                    } else if (data.data.user_type == 4) {  //驻校运营
                        window.location.replace(API_URL+'/zhuxiao/index.html')
                    }else if (data.data.user_type == 7) { //全局数据运营
                        window.location.replace(API_URL+'/caiwuWeb/index.html')
                    }else if (data.data.user_type == 8) { //产品运营
                        window.location.replace(API_URL+'/chanpinyunying/index.html')
                    }else if (data.data.user_type == 9) { //销售账号
                        // window.location = API_URL+'/xiaoshou/index.html';
                        window.location.replace(API_URL+'/xiaoshou/index.html')
                    }
                    else if (data.data.user_type == 10) { //产品运营负责人
                        window.location.replace(API_URL+'/chanpinyunyingfuzeren/index.html')
                    }
                    else if (data.data.user_type == 99) { //总部主管
                        window.location.replace(API_URL+'/caiwuWeb/index.html')
                    }
                    else if (data.data.user_type == 98) {//区域运营运营账号，管理几个省份的缴费数据查询
                        window.location.replace(API_URL+'/quyuyunying/index.html')
                    }

                } else if(data.ret==99){
                    messageApi.open({
                        type: 'warning',
                        // content: data.msg,
                        content:data.msg
                    });
                    setTimeout((e)=>{
                        sessionStorage.clear()
                        window.location.reload()
                    },1000)
                }else {
                    setTipMsg("用户名或密码错误")
                }

            })
        }
    }

    function keyPress(e) {
        if (e.which === 13) {
            loginMethod()
        }
    }

    return (
        <div className={styles.background}
             onKeyDown={keyPress}
        >
            {contextHolder}
            <img className={styles.bgImg} src={loginBgImg}></img>
            <div className={styles.contentContainer}>
                <div className={styles.contentTop}>
                    <img className={styles.topIcon} src={loginIcon}></img>
                    <div className={styles.topTextContainer}>
                        <div className={styles.BigTitle}>TBI集团综合业务管理系统</div>
                        <div className={styles.smallTitle}>Integrated Business Management System</div>
                    </div>
                </div>
                <div className={styles.contentMiddle}>
                    <div className={styles.userNameContainer}>
                        <FontAwesomeIcon icon={faUserCircle} className={styles.userIcon}/>
                        <input className={styles.userInput} placeholder='请输入用户名称'
                               onChange={(e) => setLoginUserName(e.target.value)}
                        />
                    </div>
                    <div className={styles.passwordContainer}>
                        <FontAwesomeIcon icon={faLock} className={styles.passwordIcon}/>
                        <input className={styles.userInput} placeholder='请输入登录密码'
                               type="password"
                               onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className={styles.confirmButton}
                         onClick={loginMethod}
                    >
                        <FontAwesomeIcon icon={faArrowCircleRight} className={styles.userIcon}/>
                    </div>
                </div>
                <div className={styles.contentBottom}>
                    {tipMsg != "" ? <div className={styles.contentTipContainer}>
                        <FontAwesomeIcon icon={faWarning} className={styles.tipIcon}/>
                        <span>{tipMsg}</span>
                    </div> : null}


                </div>
            </div>
            <div className={styles.footerContainer}>
                <Footer/>
            </div>
        </div>
    )
}
